export default {
  BUTTON_PRIMARY_CLASS: 'button button--primary',
  BUTTON_SECONDARY_CLASS: 'button button-secondary',
  LOAD_MORE_BUTTON_CLASS: 'button button--primary',
  SHOW_RESULTS_CLASS: 'button button--primary',

  FACET_INPUT_CLASS: 'form-input',
  FACET_DIALOG_BUTTON_CLASS: 'button button--primary',

  SEARCH_BOX_INPUT_CLASS: 'form-input',
  SEARCH_BOX_BUTTON_CLASS: 'input-group-append',
  SEARCH_BOX_PLACEHOLDER: 'Search the store',
};
